<template>
  <div>
    <b-card title="Vous pouvez rechercher les orders directement ici.">
      <b-row>
        <b-col
          cols="6"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="search"
                placeholder="Rechercher un sku..."
                style="height: auto;"
                class="border-0 shadow-none"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col
          v-if="orders && orders.length"
          cols="2"
        >
          <download-csv
            :data="orders.map(l => ({sku: l.sku, type: l.alert ? 'A COMMANDER' : 'STOCK SUFFISANT', stock: (l.agediss + l.colisland) - l.waiting, orderien: l.orderien, 'M-3': l.quantityOrder, 'M-6': l.quantityOrderM6, 'Y - 1 / M + 6': l.quantityOrderOld, 'Y - 1 / M - 3': l.quantityOrderOldSame, orderHistoric: l.orderHistoric && l.orderHistoric.length ? l.orderHistoric.map(l => `${$moment(l.date).format('DD/MM/YYYY')}: ${l.quantity}`).join(' - '): '' }))"
            name="order.csv">
            <b-button class="mb-2" variant="warning">Télécharger</b-button>
          </download-csv>
        </b-col>
      </b-row>

      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row v-else class="match-height mb-2">
        <b-table
          ref="selectableTable"
          small
          selectable
          :items="orders"
          :fields="fields"
        >
          <template #cell(show_details)="data">
            <b-form-checkbox
              v-model="data.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="data.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ data.detailsShowing ? 'Cacher' : 'Voir' }}</span>
            </b-form-checkbox>
          </template>
          <template #cell(sku)="data">
            <p>{{ data.item.sku }} <br><span>{{ data.item.product }}</span></p>
          </template>
          <!-- <template #cell(order)="data">
            <p>{{ data.item.orderHistoric && data.item.orderHistoric.length ? data.item.orderHistoric.reduce((a, b) => (a.quantity || 0) + (b.quantity || 0) ) : '' }}</p>
          </template> -->
          <template #cell(sinistre)="data">
            <b-badge v-for="(product, key) of Object.entries(data.item.products.reduce((acc, { sinistre }) => (acc[sinistre] = (acc[sinistre] || 0) + 1, acc), {})).map(([sinistre, count]) => ({ sinistre, count }))" :key="key" :variant="`light-${variants.find(v => product.sinistre === v.category).variant}`" class="mr-1">
              {{ `${product.count} ${product.sinistre}` }}
            </b-badge>
          </template>
          <template #cell(_id.date_created)="data">
            {{ $moment(data.item._id.date_created).format('DD/MM/YYYY') }}
          </template>
          <template #row-details="data">
            <p><strong><u>Détails produits:</u></strong></p>
            <b-table-lite
              responsive
              :items="data.item.products"
              :fields="['name', 'sku', 'sinistre', 'date']"
            >
              <template #cell(date)="data">
                {{ $moment(data.item.date).format('DD/MM/YYYY') }}
              </template>
            </b-table-lite>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="itemsPerPage"
          first-number
          last-number
          class="mb-0 mt-2 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @change="getOrders"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-row>
      <GraphSinistre v-if="sinistreData" class="mt-2" :data="sinistreData" />
      <!-- <GraphSinistre v-if="sinistreProductData" :data="sinistreProductData" /> -->
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  BBadge,
  BTable,
  VBPopover,
  BButton,
  BFormCheckbox,
  BTableLite,
  BPagination,
} from 'bootstrap-vue'
import { required } from '@validations'
import GraphSinistre from '@components/orders/GraphSinistre.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import JsonCSV from 'vue-json-csv'

export default {
  components: {
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    BBadge,
    BInputGroup,
    BPagination,
    BButton,
    BFormCheckbox,
    BTableLite,
    GraphSinistre,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
    'download-csv': JsonCSV,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      currentPage: 10,
      totalPages: 1,
      totalItems: 1,
      itemsPerPage: 25,
      baseOrders: [],
      orders: [],
      products: [],
      categories: [],
      sinistreData: null,
      sinistreProductData: [],
      order: {},
      loading: false,
      conditionalProducts: '',
      conditionalCategories: '',
      variants: [
        { variant: 'warning', category: 'Problème de livraison' },
        { variant: 'danger', category: 'Défectueux' },
      ],
      orderOptions: ['Dequecker', 'GommaGomma', 'Valrupt', 'Samarfil', 'Sabbe', 'Radi', 'Homespirit', 'Drouault', 'Cogal', 'Abeil'],
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'show_details', label: 'Historique' }, { key: '_id.number', label: 'Order', sortable: true }, { key: '_id.date_created', label: 'Date commande', sortable: true }, { key: 'sinistre', label: 'Nombre de sinistre', sortable: true }],
      showModal: false,
      categoryInformations: [],
      orderOrder: [],
    }
  },
  async mounted() {
    try {
      await this.getOrders(1)
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async saveOrder(item) {
      try {
        await this.$http.put(`/admin/order/${item._id}`, { item })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement réussi.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    async getOrders(e) {
      try {
        this.loading = true
        this.currentPage = e
        const option = {
          page: this.currentPage,
          itemsPerPage: this.itemsPerPage,
        }
        const search = this.searchQuery
        const {
          orders,
          totalPages,
          totalItems,
          itemsPerPage,
          sinistreData,
          sinistreProductData,
        } = await this.$http.get('/admin/sinistre', { params: { ...option, filter: this.filter, search } })
        this.orders = orders
        this.totalPages = totalPages
        this.totalItems = totalItems
        this.itemsPerPage = itemsPerPage
        this.sinistreData = sinistreData
        this.sinistreProductData = sinistreProductData
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    async updateOrder() {
      try {
        await this.$http.post('/admin/order/bl-historic', { logs: this.orderOrder })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Enregistrement effectué',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.orderOrder = []
        this.getOrders()
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
