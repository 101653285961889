<template>
  <div>
    <apexchart
      v-if="chartData.series.length"
      type="line"
      :options="chartOptions"
      :series="chartData.series"
      height="350"
    ></apexchart>
    <div v-else>
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartData: {
        series: [],
      },
      chartOptions: {
        chart: {
          id: 'sinistre-chart',
          toolbar: {
            show: true,
          },
        },
        title: {
          text: 'Évolution des Types de Sinistres par Mois',
        },
        xaxis: {
          categories: [], // Remplie plus tard avec les mois
        },
        yaxis: {
          title: {
            text: 'Nombre de Sinistres',
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'center',
        },
        stroke: {
          width: 2,
          curve: 'smooth', // Pour une courbe lissée
        },
        dataLabels: {
          enabled: true, // Pour afficher les valeurs au-dessus des points
        },
      },
      isLoading: true,
    }
  },
  async created() {
    // Crée un objet pour stocker les comptes par mois et type
    const typeCounts = {}

    this.data.forEach(sinistre => {
      const { month, type, count } = sinistre // Déstructure les données
      if (!typeCounts[type]) {
        typeCounts[type] = {}
      }
      typeCounts[type][month] = count
    })

    // Prépare les séries et catégories pour le graphique
    this.chartOptions.xaxis.categories = Array.from(
      new Set(this.data.map(s => s.month)),
    ) // Obtenir les mois uniques
    this.chartData.series = Object.keys(typeCounts).map(type => ({
      name: type,
      data: this.chartOptions.xaxis.categories.map(
        month => typeCounts[type][month] || 0,
      ), // Récupère les comptes ou 0 s'il n'y a pas de données
    }))
  },
  methods: {
    prepareChartData(data) {
      // Crée un objet pour stocker les comptes par mois et type
      const typeCounts = {}

      data.forEach(sinistre => {
        const { month, type, count } = sinistre // Déstructure les données
        if (!typeCounts[type]) {
          typeCounts[type] = {}
        }
        typeCounts[type][month] = count
      })

      // Prépare les séries et catégories pour le graphique
      this.chartOptions.xaxis.categories = Array.from(
        new Set(data.map(s => s.month)),
      ) // Obtenir les mois uniques
      this.chartData.series = Object.keys(typeCounts).map(type => ({
        name: type,
        data: this.chartOptions.xaxis.categories.map(
          month => typeCounts[type][month] || 0,
        ), // Récupère les comptes ou 0 s'il n'y a pas de données
      }))
    },
  },
}
</script>

<style scoped>
/* Ajouter du style si nécessaire */
</style>
